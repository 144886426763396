<script>
import store from "@/state/store";
import Swal from "sweetalert2";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core/dist/index.esm";

export default {
  name : 'Modal',

  setup() {
    return { v$: useVuelidate() };
  },

  props : {
    modalData : {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      title: "비밀번호 변경",
      data: this.$props.modalData,
      showModal: false,
      formData: {
        uid: "",
        password: "",
      },
      formSubmitted: false,
    }
  },

  mounted() {
    this.showModal = true
  },

  validations: function() {
    return {
      formData: {
        password: {
          required: helpers.withMessage("비밀번호를 입력해 주세요.", required)
        },
      }
    }
  },

  methods: {
    openModal() {
      window.history.pushState({}, '', '#modal');
      window.onpopstate = history.onpushstate = () => {
        this.showModal = false
      }
    },

    closeModal() {
      this.$emit('close');
      window.history.back()
    },

    // 저장
    submitForm() {
      store.dispatch('notification/clear')

      // stop here if form is invalid
      this.v$.$touch();

      this.formSubmitted = true

      if (this.v$.formData.$invalid) {
        this.formSubmitted = false
      } else {
        let params = {
          uid: this.data.uid,
          password: this.formData.password
        }

        store.dispatch("apiUsers/changePassword", params).then((result) => {
          this.formSubmitted = false
          Swal.fire({
            text: result.message,
            icon: result.success ? "success" : "error"
          }).then(result => {
            if (result.value) {
                window.location.reload()
            }
          });
        }, () => {
          this.formSubmitted = false
        });
      }
    },
  },

  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    setTitle() {
      return this.data.name + "님의 "+ this.title
    },
  }
}
</script>

<template>
  <b-modal
      id="my-modal"
      v-model="showModal"
      @show="openModal"
      @hidden="closeModal"
      centered
      size="sm"
      :title="setTitle"
      title-class="font-size-15"
      hide-footer
    >
    <div v-if="notification.message" :class="'alert ' + notification.type">{{ notification.message }}</div>
    <b-form @submit.prevent="submitForm">
      <b-form-group
          class="mb-2"
          label="새 비밀번호"
          label-for="password"
          :invalid-feedback="v$.formData.password.$error ? v$.formData.password.$errors[0].$message : null"
          :state="!v$.formData.password.$error"
      >
        <b-form-input
            type="password"
            id="password"
            name="password"
            v-model="formData.password"
            :state="!v$.formData.password.$error"
            :disabled="formSubmitted"
        ></b-form-input>
      </b-form-group>
      <div class="my-2">
        <b-button variant="danger" class="me-2" type="submit" :disabled="formSubmitted">변경하기</b-button>
        <b-button variant="secondary" id="btn-cancel" data-bs-dismiss="modal">취소</b-button>
      </div>
    </b-form>
  </b-modal>
</template>
