<script>
import store from "@/state/store";
import Tablesaw from 'tablesaw'
import {reloadTablesaw} from "@/helpers/datatable";
import Swal from "sweetalert2";
import MemoModal from "@/components/modals/user-memo";

export default {
  name: 'recommends',

  components: {
    MemoModal,
  },

  props : {
    userType : {
      type: String,
      default: ''
    },
    userUid : {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      uid: this.$props.userUid,
      type: this.$props.userType,
      user: {},
      referral: {},
      recommends: {},
      showModal: {
        memo: false,
      },
      modalData: {},
    }
  },

  mounted() {
    this.loadRecommends()
  },

  methods: {
    loadRecommends() {
      Tablesaw._init();

      let uid = this.uid
      store.dispatch("apiUsers/recommends", uid).then((result) => {
        this.user = result.data.user
        this.referral = result.data.referral
        this.recommends = result.data.recommends
        this.$nextTick(function () {
          reloadTablesaw(this.$refs.recommends)
        })
      })
    },

    // 추천인 초기화
    recommendReset() {
      Swal.fire({
        title: "현재 회원의 추천인을 초기화 하시겠습니까?",
        text: "테스트 용도로만 사용해 주세요!!",
        icon: "warning",
        confirmButtonText: "초기화",
        cancelButtonText: "취소",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.requestRecommendReset()
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('cancel')
        }
      });
    },

    // 추천인 초기화 서버요청
    requestRecommendReset() {
      let uid = this.uid
      if (uid) {
        store.dispatch("apiUsers/recommendReset", uid).then((result) => {
          Swal.fire({
            text: result.message,
            icon: result.success ? "success" : "error",
            confirmButtonText: "확인",
          }).then(result => {
            if (result.value) {
              this.loadRecommends()
            }
          });
        });
      }
    },

    phoneFormat(phone) {
      return phone.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3")
    },

    openMemo() {
      this.modalData = this.user
      this.showModal.memo = true
    },
  },
};
</script>

<template>
  <memo-modal
      v-if="showModal.memo"
      :modal-data="modalData"
      @close="showModal.memo = false"
      @loadRecommends="loadRecommends"
  />

  <div class="mt-2 pb-1 position-relative">
    <span class="fw-semibold">{{ user.name }}</span>님이 추천한 회원
    <div class="position-absolute end-0 bottom-0">
      <b-button v-if="!user.referral_code" size="sm" variant="primary" class="spacing-5 ms-2 mb-1" @click="recommendReset()">초기화</b-button>
      <b-button variant="success" size="sm" class="ms-2 mb-1" @click="openMemo()">메모 {{ user.memo ? '수정' : '등록' }}</b-button>
    </div>
  </div>
  <div class="mt-1">
    <div class="form-control bg-light">
      <span v-if="referral"><router-link class="pointer dotted-link" :to="`/users/${$route.params.type}/${referral.uid}`">{{ referral.name }} ({{ referral.phone }})</router-link>, </span>
      <span v-if="user.referral_code" class="d-block d-sm-inline-block">{{ user.referral_code }} ({{ user.referral_at }})</span>
      <span v-else>추천한 회원이 없습니다.</span>
    </div>
  </div>

  <div class="mt-4 mb-3">
    <label class="form-label"><span class="fw-semibold">{{ user.name }}</span>님 메모</label>
    <div class="form-control bg-light">
      <span v-if="user.memo" v-html="$nl2br(user.memo)"></span>
      <span v-else>메모가 없습니다.</span>
    </div>
  </div>

  <div class="mt-4">
    <div class="float-start pb-2">
      <span class="fw-semibold">{{ user.name }}</span>님을 추천한 회원 (<span class="text-primary fw-semibold">{{ user.referrals_count }}</span>)
    </div>
  </div>
  <table ref="recommends" class="tablesaw table align-middle table-nowrap table-hover" data-tablesaw-mode="columntoggle">
    <thead class="table-light">
    <tr>
      <th scope="col" class="wd-80" data-tablesaw-priority="persist">회원명</th>
      <th scope="col" class="text-start" data-tablesaw-priority="persist">휴대폰</th>
      <th scope="col" class="wd-80" data-tablesaw-priority="3">초대코드</th>
      <th scope="col" class="wd-100" data-tablesaw-priority="persist">추천일시</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="list in recommends" :key="list.uid">
      <td><router-link class="pointer dotted-link" :to="`/users/${$route.params.type}/${list.uid}`">{{ list.name }}</router-link></td>
      <td class="text-start"><router-link class="pointer dotted-link" :to="`/users/${$route.params.type}/${list.uid}`"><span>{{ phoneFormat(list.phone) }}</span></router-link></td>
      <td>{{ list.my_referral_code }}</td>
      <td>{{ list.referral_at.substring(2,16).replaceAll('-','.') }}</td>
    </tr>
    </tbody>
  </table>
  <div v-if="!recommends.length">
    <p class="py-3 text-secondary text-center">추천회원이 없습니다.</p>
  </div>
</template>
