<script>
import store from "@/state/store";
import Tablesaw from 'tablesaw'
import {reloadTablesaw} from "@/helpers/datatable";
import ZoneModal from "@/components/modals/user-zone";
import FamilyZoneModal from "@/components/modals/family-zone";

const paramsFamily = {
  parent_uid : '',
  child_uid : '',
  parent_status : 'Agree',
  child_status : 'Agree',
  withTrashed: false,
}

export default {
  name: 'relations',

  components: {
    ZoneModal,
    FamilyZoneModal,
  },

  props : {
    userType : {
      type: String,
      default: ''
    },
    userUid : {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      uid: this.$props.userUid,
      type: this.$props.userType,
      user: {},
      parents: [],
      children: [],
      zones: [],
      showModal: {
        zone: false,
        familyZone: false,
      },
      modalData: {},
    }
  },

  mounted() {
    this.loadUser()
    this.loadChildren()
    this.loadParents()
    this.loadZones()
  },

  methods: {
    loadUser() {
      let type = this.type
      let uid = this.uid

      this.isPageLoading = true
      store.dispatch("apiUsers/create", { type, uid }).then((result) => {
        this.user = result.data.data
      })
    },

    loadChildren() {
      Tablesaw._init();

      let params = paramsFamily

      params.child_uid = ''
      params.parent_uid = this.uid
      store.dispatch("apiUsers/family", params).then((result) => {
        this.children = result.data
        this.$nextTick(function () {
          reloadTablesaw(this.$refs.children)
        })
      })
    },

    loadParents() {
      Tablesaw._init();

      let params = paramsFamily

      params.parent_uid = ''
      params.child_uid = this.uid
      store.dispatch("apiUsers/family", params).then((result) => {
        this.parents = result.data
        this.$nextTick(function () {
          reloadTablesaw(this.$refs.parents)
        })
      })
    },

    loadZones() {
      Tablesaw._init();

      let params = paramsFamily

      params.child_uid = ''
      params.parent_uid = this.uid
      store.dispatch("apiUsers/zones", params).then((result) => {
        this.zones = result.data
        this.$nextTick(function () {
          reloadTablesaw(this.$refs.zones)
        })
      })
    },

    openZone(data) {
      this.modalData = data
      this.showModal.zone = true
    },

    openFamilyZone(data, childName, parentName) {
      this.modalData = {
        list: data,
        childName: childName,
        parentName: parentName
      };
      this.showModal.familyZone = true;
    },
  }
};
</script>

<template>
  <zone-modal v-if="showModal.zone" :modal-data="modalData" @close="showModal.zone = false" />
  <family-zone-modal v-if="showModal.familyZone" :modal-data="modalData" @close="showModal.familyZone = false" />

  <div class="row">
    <div class="col-12 col-md-6">
      <div class="font-size-14 pb-2">{{ user.name }}님의 <span class="fw-semibold">피보호자</span></div>
      <table ref="children" class="tablesaw table align-middle table-nowrap table-hover" data-tablesaw-mode="columntoggle">
        <thead class="table-light">
        <tr>
          <th scope="col" class="wd-80" data-tablesaw-priority="persist">이름</th>
          <th scope="col" class="text-start" data-tablesaw-priority="persist">휴대폰</th>
          <th scope="col" class="wd-50" data-tablesaw-priority="persist">안심존</th>
          <th scope="col" class="wd-30" data-tablesaw-priority="persist">위치</th>
          <th scope="col" class="wd-100" data-tablesaw-priority="3">요청일</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="list in children" :key="list.child_uid">
          <td><router-link class="pointer dotted-link" :to="`/users/${$route.params.type}/${list.child_uid}`">{{ list.child_name_origin }}</router-link></td>
          <td class="text-start"><router-link class="pointer dotted-link" :to="`/users/${$route.params.type}/${list.child_uid}`">{{ list.child_phone_format }}</router-link></td>
          <td>
            <b-button
                v-if="list.zones && list.zones.length"
                variant="info"
                class="font-size-12 btn-rounded px-2 py-0"
                @click="openFamilyZone(list.zones, list.child_name_origin, user.name)"
            >{{ list.zones.length }}</b-button>
            <span v-else>-</span>
          </td>
          <td class="spacing-10"><span :class="{ 'text-danger': !list.is_shared_location }">{{ list.is_shared_location ? '허용' : '숨김' }}</span></td>
          <td class="spacing-10"><span>{{ list.created_datetime }}</span></td>
        </tr>
        </tbody>
      </table>
      <div v-if="!children.length">
        <p class="py-3 text-secondary text-center">등록된 피보호자가 없습니다.</p>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="font-size-14 pb-2">{{ user.name }}님의 <span class="fw-semibold">보호자</span></div>
      <table ref="parents" class="tablesaw table align-middle table-nowrap table-hover" data-tablesaw-mode="columntoggle">
        <thead class="table-light">
        <tr>
          <th scope="col" class="wd-80" data-tablesaw-priority="persist">이름</th>
          <th scope="col" class="text-start" data-tablesaw-priority="persist">휴대폰</th>
          <th scope="col" class="wd-50" data-tablesaw-priority="persist">안심존</th>
          <th scope="col" class="wd-30" data-tablesaw-priority="persist">위치</th>
          <th scope="col" class="wd-100" data-tablesaw-priority="3">요청일</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="list in parents" :key="list.parent_uid">
          <td><router-link class="pointer dotted-link" :to="`/users/${$route.params.type}/${list.parent_uid}`">{{ list.parent_name_origin }}</router-link></td>
          <td class="text-start"><router-link class="pointer dotted-link" :to="`/users/${$route.params.type}/${list.parent_uid}`">{{ list.parent_phone_format }}</router-link></td>
          <td>
            <b-button
                v-if="list.zones && list.zones.length"
                variant="info"
                class="font-size-12 btn-rounded px-2 py-0"
                @click="openFamilyZone(list.zones, user.name, list.parent_name_origin)"
            >{{ list.zones.length }}</b-button>
            <span v-else>-</span>
          </td>
          <td class="spacing-10"><span :class="{ 'text-danger': !list.is_shared_location }">{{ list.is_shared_location ? '허용' : '숨김' }}</span></td>
          <td class="spacing-10"><span>{{ list.created_datetime }}</span></td>
        </tr>
        </tbody>
      </table>
      <div v-if="!parents.length">
        <p class="py-3 text-secondary text-center">등록된 보호자가 없습니다.</p>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12">
      <div class="font-size-14 pb-2">{{ user.name }}님의 <span class="fw-semibold">안심존</span></div>
      <table ref="zones" class="tablesaw table align-middle table-nowrap table-hover" data-tablesaw-mode="columntoggle">
        <thead class="table-light">
        <tr>
          <th scope="col" class="wd-80" data-tablesaw-priority="persist">피보호자</th>
          <th scope="col" class="wd-100 text-start" data-tablesaw-priority="persist">휴대폰</th>
          <th scope="col" class="text-start" data-tablesaw-priority="6">주소</th>
          <th scope="col" class="wd-60" data-tablesaw-priority="4">구분</th>
          <th scope="col" class="wd-50" data-tablesaw-priority="persist">안심존</th>
          <th scope="col" class="wd-50" data-tablesaw-priority="3">반경</th>
          <th scope="col" class="wd-40" data-tablesaw-priority="persist">상태</th>
          <th scope="col" class="wd-100" data-tablesaw-priority="5">등록일</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="list in zones" :key="list.uid">
          <td>
            <a class="dotted-link" href="javascript:void(0);" @click="openZone(list)">{{ list.child_name }}</a>
          </td>
          <td class="text-start"><a class="dotted-link" href="javascript:void(0);" @click="openZone(list)">{{ list.child_phone }}</a></td>
          <td class="text-start ellipsis ellipsis-wide"><span>{{ list.address }}</span></td>
          <td><span>{{ list.code_name }}</span></td>
          <td><span>{{ list.name }}</span></td>
          <td><span>{{ list.radius }}</span></td>
          <td>
            <span v-if="list.action_code == 'out'" class="text-danger">이탈</span>
            <span v-else class="text-primary">진입</span>
          </td>
          <td class="spacing-10"><span>{{ list.created_datetime }}</span></td>
        </tr>
        </tbody>
      </table>
      <div v-if="!zones.length">
        <p class="py-3 text-secondary text-center">등록된 안심존이 없습니다.</p>
      </div>
    </div>
  </div>
</template>
