<script>
export default {
  name : 'device',

  props : {
    modalData : {
      type: Object,
      default: () => {
        return {}
      }
    },
  },

  data() {
    return {
      title: "기기정보",
      data: this.$props.modalData,
      showModal: false,
    }
  },

  mounted() {
    this.showModal = true
  },

  methods: {
    openModal() {
      window.history.pushState({}, '', '#modal');
      window.onpopstate = history.onpushstate = () => {
        this.showModal = false
      }
    },

    closeModal() {
      this.$emit('close');
      window.history.back()
    },
  },

  computed: {
    setTitle() {
      return this.data.name + "님의 "+ this.title
    },
  }
}
</script>

<template>
  <b-modal
      id="my-modal"
      v-model="showModal"
      @show="openModal"
      @hidden="closeModal"
      centered
      :title="title"
      title-class="font-size-15 fw-bold"
      hide-footer
  >
    <div class="mb-3">
      <label class="form-label">기기모델</label>
      <div class="form-control bg-light">{{ data.model ? data.model : '-' }}</div>
    </div>
    <div class="mb-3">
      <label class="form-label">디바이스 ID</label>
      <div class="form-control bg-light">{{ data.device_id ? data.device_id : '-' }}</div>
    </div>
    <div class="mb-3">
      <label class="form-label">디바이스 OS</label>
      <div class="form-control bg-light">{{ data.device_os ? data.device_os : '-' }}</div>
    </div>
    <div class="mb-3">
      <label class="form-label">디바이스 버전</label>
      <div class="form-control bg-light">{{ data.device_version ? data.device_version : '-' }}</div>
    </div>
    <div class="mb-3">
      <label class="form-label">디바이스 상세</label>
      <div class="form-control bg-light" v-html="$nl2br(data.device_detail)"></div>
    </div>
    <div class="mb-3">
      <label class="form-label">접속상태</label>
      <div class="form-control bg-light">
        <div v-if="data.device_os">
          <img v-if="data.device_os == 'Android'" :src="require(`@/assets/images/social/icon_android.png`)" height="16" class="me-1" v-b-tooltip.hover title="Android" />
          <img v-if="data.device_os == 'iOS'" :src="require(`@/assets/images/social/icon_apple.png`)" height="16" class="me-1" v-b-tooltip.hover title="Apple" />
          <img v-if="data.device_os == 'Android'" :src="require(`@/assets/images/device/ic_state_power${ !data.is_power ? '_off' : '' }.png`)" height="16" class="me-1" v-b-tooltip.hover :title="data.is_power ? 'ON' : 'OFF'" />
          <img :src="require(`@/assets/images/device/ic_state_gps${ !data.is_gps ? '_off' : '' }.png`)" height="16" class="me-1" v-b-tooltip.hover :title="data.is_gps ? 'ON' : 'OFF'" />
          <img v-if="data.device_os == 'Android'" :src="require(`@/assets/images/device/ic_state_mobile${ !data.is_mobile ? '_off' : '' }.png`)" height="16" class="me-1" v-b-tooltip.hover :title="data.is_mobile ? 'ON' : 'OFF'" />
          <img v-if="data.device_os == 'Android'" :src="require(`@/assets/images/device/ic_state_wifi${ !data.is_wifi ? '_off' : '' }.png`)" height="16" class="me-1" v-b-tooltip.hover :title="data.is_wifi ? 'ON' : 'OFF'" />
        </div>
        <span v-else>-</span>
      </div>
    </div>
    <div class="mb-3">
      <label class="form-label">최근 위치</label>
      <div class="form-control bg-light">
        <span class="font-size-12" :class="{ 'text-danger': !data.last_address }">{{ data.last_address ? data.last_address : '수집 X' }}</span>
      </div>
    </div>
  </b-modal>
</template>