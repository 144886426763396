<script>
import store from "@/state/store";
import Swal from "sweetalert2";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core/dist/index.esm";

export default {
  name : 'MemoModal',

  setup() {
    return { v$: useVuelidate() };
  },

  props : ["modalData"],

  data() {
    return {
      title: "메모",
      showModal: false,
      data : this.modalData,
      formData: {
        uid: "",
        memo: "",
      },
      formSubmitted: false,
    }
  },

  mounted() {
    this.loadMemo()
    this.showModal = true
  },

  validations: function() {
    return {
      formData: {
        memo: {
          required: helpers.withMessage("메모내용을 입력해 주세요.", required)
        },
      }
    }
  },

  methods: {
    openModal() {
      window.history.pushState({}, '', '#modal');
      window.onpopstate = history.onpushstate = () => {
        this.showModal = false
      }
    },

    closeModal() {
      this.$emit('close');
      window.history.back()
    },

    loadMemo() {
      if (this.data) {
        this.formData.uid = this.data.uid
        this.formData.memo = this.data.memo
      }
    },

    // 저장
    submitForm() {
      store.dispatch('notification/clear')

      this.v$.$touch();

      this.formSubmitted = true

      if (this.v$.formData.$invalid) {
        this.formSubmitted = false
      } else {
        let params = {
          uid: this.data.uid,
          memo: this.formData.memo
        }

        store.dispatch("apiUsers/memoChange", params).then((result) => {
          this.formSubmitted = false
          Swal.fire({
            text: result.message,
            icon: result.success ? "success" : "error"
          }).then(result => {
            if (result.value) {
              this.$emit('loadRecommends');
              document.querySelector("#btn-cancel").click();
            }
          });
        }, () => {
          this.formSubmitted = false
        });
      }
    },
  },

  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    setTitle() {
      return this.data.name + "님의 "+ this.title
    },
  }
}
</script>

<template>
  <b-modal
      id="my-modal"
      v-model="showModal"
      @show="openModal"
      @hidden="closeModal"
      centered
      size="sm"
      :title="setTitle"
      title-class="font-size-15"
      hide-footer
    >
    <div v-if="notification.message" :class="'alert ' + notification.type">{{ notification.message }}</div>
    <b-form @submit.prevent="submitForm">
      <b-form-group
          label="메모내용"
          label-for="memo"
          :invalid-feedback="v$.formData.memo.$error ? v$.formData.memo.$errors[0].$message : null"
          :state="!v$.formData.memo.$error"
      >
        <b-form-textarea
            id="memo"
            name="memo"
            v-model="formData.memo"
            style="height:150px"
            placeholder="메모내용을 입력해 주세요."
            :state="!v$.formData.memo.$error"
            :disabled="formSubmitted"
        ></b-form-textarea>
      </b-form-group>
      <div class="my-2">
        <b-button variant="danger" class="me-2" type="submit" :disabled="formSubmitted">저장</b-button>
        <b-button variant="secondary" id="btn-cancel" data-bs-dismiss="modal">취소</b-button>
      </div>
    </b-form>
  </b-modal>
</template>
