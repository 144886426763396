<script>
import VueNaverMap from "@/components/widgets/vue-naver-map";
import VueNaverPano from "@/components/widgets/vue-naver-pano";
import {apiMapMethods} from "@/state/helpers";
import Slider from "@vueform/slider";
import store from "@/state/store";
import moment from "moment";

export default {
  components: {
    VueNaverMap,
    VueNaverPano,
    Slider,
  },
  name : 'UserLocationModal',
  props : ["modalData"],
  data() {
    return {
      data : this.modalData,
      showModal : false,
      naverMap: null,
      mapOptions: {
        lat: 37,
        lng: 127,
        zoom: 10,
        zoomControl: true,
        zoomControlOptions: {position: 'TOP_RIGHT'},
        mapTypeControl: true,
      },
      slideNumber: 0,
      userLocations: [],
      currentLocation: [],
      sliderFormat: (value) => {
        let index = parseInt(value)
        let data = this.userLocations[index];
        if (data && data.timestamp) {
          return data.timestamp.substring(11, 19);
        }
        return index
      },
      isLoaded: false,
      isOpenPano: false,
      searchDate: new Date(moment().format('YYYY-MM-DD')),

      zones: [],
      parents: [],

      addressTimer: null,
      infoWindow: null,
    }
  },
  mounted() {
    this.showModal = true;
    setTimeout(()=> {
      this.isLoaded = true;
    }, 1000)
  },
  methods: {
    ...apiMapMethods,
    openModal() {
      window.history.pushState({}, '', '#modal');
      window.onpopstate = history.onpushstate = () => {
        this.showModal = false
      }
    },

    closeModal() {
      this.$emit('close');
      window.history.back()
    },

    initMap(naverMap) {
      this.naverMap = naverMap;
      this.requestFamilyZones();
      this.requestLocation();
    },

    clearMap() {
      this.currentLocation = [];
      this.userLocations = [];
      this.naverMap.removeMarkerAll();
      this.naverMap.resetBounds();
      this.naverMap.clearLine();
      this.naverMap.clearZone();
      this.clearParents();
      this.slideNumber = 0;

      if (this.infoWindow) {
        this.infoWindow.setMap(null);
        this.infoWindow = null;
      }
    },

    requestLocation() {
      this.clearMap();

      let uid = this.data.uid
      let params = {
        date: moment(this.searchDate).format('YYYY-MM-DD')
      }
      store.dispatch("apiUsers/locations", { uid, params }).then((result) => {
        let locations = result.data
        if (locations && locations[0] && locations[0][0]) {
          this.isLoaded = true;
          this.userLocations = locations[0]
          this.drawMapLine(locations)
        }
      })
    },

    generatorAddressLocation(data) {
      this.addressTimer = setTimeout(() => {
        let params = {
          latitude: data.latitude,
          longitude: data.longitude
        };

        store.dispatch("apiMap/coordsToAddress", params).then((result) => {
          data.address = result.data;

          let map = this.naverMap;
          let position = map.createLatLng(data.latitude, data.longitude);
          let content = map.getNumberInfoHtml(data);

          map.maps.setCenter(position);
          this.infoWindow = map.createInfoWindow(content);
          this.infoWindow.open(map.maps, position);
        });
      }, 500);
    },

    drawMapLine(rows) {
      if (!rows || !rows.length) {
        return;
      }

      let paths = [];

      let number = 1;
      let beforeLocation = null;

      let locations = rows[0];
      let marker = [];

      for (let i in locations) {
        let location = locations[i]
        paths.push(this.naverMap.createLatLng(location.latitude, location.longitude));

        // 처음 위치와 3분 이상 머문 위치 표시
        if (number == 1 || location.staytime >= 60 * 3) {
          marker[number] = this.naverMap.addNumberMarker(number, location);
          number++;
          beforeLocation = location;
        }
      }

      // 마지막 위치 표시
      if (locations.length > 0) {
        let last = locations[locations.length - 1];
        if (beforeLocation !== last) {
          this.naverMap.addNumberMarker(number, last);
        }
      }

      if (paths.length > 1) {
        this.naverMap.drawLine(paths);
      }
      this.naverMap.fitBounds(paths);

      if (paths.length) {
        this.currentLocation = locations[0];
        clearTimeout(this.addressTimer)
        this.generatorAddressLocation(this.currentLocation)
      }
    },

    requestFamilyZones() {
      let uid = this.data.uid;
      store.dispatch("apiUsers/familyZones", uid).then((result) => {
        let rows = result.data;
        let zones = {};
        if (rows.length > 0) {
          for (let i in rows) {
            let item = rows[i];
            let uid = item.family.parent.uid;
            if (!(uid in zones)) {
              zones[uid] = {};
              zones[uid]['name'] = item.family.parent.name;
              zones[uid]['data'] = [];
              this.parents.push(item.family.parent);
            }
            zones[uid]['data'].push(item);
          }
          this.zones = zones;
        }
      });
    },

    showZones(e, uid) {
      if (!uid) return;

      let element = e.target;
      if (element.classList.contains('active-zone')) {
        element.classList.remove('active-zone');
        this.naverMap.clearZone();
      } else {
        this.clearParents();
        element.classList.add('active-zone');
        this.naverMap.loadZones(this.zones[uid]['data']);
      }
    },

    clearParents() {
      document.querySelectorAll(".zone-item").forEach(item => {
        item.classList.remove('active-zone');
      });
    },

    updateOpenPano(isOpen) {
      this.isOpenPano = isOpen
    },

    openPano() {
      if (this.currentLocation.latitude && this.currentLocation.longitude) {
        this.isOpenPano = true
        setTimeout(()=> {
          this.$refs.naverPano.open(this.currentLocation.latitude, this.currentLocation.longitude)
        }, 1000)
      }
    },

    prevDate() {
      this.searchDate = new Date(moment(this.searchDate).add(-1, 'day').format('YYYY-MM-DD'))
      this.requestLocation();
    },

    nextDate() {
      this.searchDate = new Date(moment(this.searchDate).add(+1, 'day').format('YYYY-MM-DD'))
      this.requestLocation();
    },

    changeDate() {
      this.requestLocation();
    },
  },
  watch: {
    slideNumber(newVal, oldVal) {
      if (newVal !== oldVal) {
        let data = this.userLocations[newVal];
        if (data) {
          this.currentLocation = data
          this.naverMap.openInfoWindow(data.latitude, data.longitude, this.naverMap.getNumberInfoHtml(data));
          clearTimeout(this.addressTimer)
          this.generatorAddressLocation(data)
          if (this.isOpenPano) {
            this.$refs.naverPano.open(this.currentLocation.latitude, this.currentLocation.longitude)
          }
        }
      }
    },
  },
}
</script>

<template>
  <b-modal
      v-model="showModal"
      id="user-location-modal"
      @show="openModal"
      @hidden="closeModal"
      centered
      fullscreen
      :title="data.name+'님 위치조회'"
      title-class="font-15 fw-bold"
      hide-footer
  >
    <div v-show="!isOpenPano" class="select-date">
      <div class="d-flex position-relative">
        <button class="btn btn-icon me-n2" @click="prevDate()">
          <i class="bx bxs-left-arrow-circle font-size-24 text-secondary"></i>
        </button>
        <date-picker
            input-class="form-control wd-120"
            v-model:value="searchDate"
            :clearable="false"
            value-type="date"
            @change="changeDate()"
        ></date-picker>
        <button class="btn btn-icon ms-n2" @click="nextDate()">
          <i class="bx bxs-right-arrow-circle font-size-24 text-secondary"></i>
        </button>
      </div>
    </div>
    <div class="position-absolute map">
      <div class="position-absolute" style="bottom:25px;left:40px;right:40px;z-index:9999">
        <Slider v-model="slideNumber" :format="sliderFormat" :lazy="false" :min="0" :max="(userLocations && userLocations.length > 0) ? userLocations.length-1 : 0" />
      </div>
      <div v-if="parents.length" class="zone-box">
        <div class="text-center font-size-13 fw-bold mb-2">보호자 목록</div>
        <div class="zone-list">
          <div v-for="parent in parents" :key="parent.uid" class="zone-item" @click="showZones($event, parent.uid)">{{ parent.name }}</div>
        </div>
      </div>
      <VueNaverMap v-if="isLoaded" v-show="!isOpenPano" ref="naverMap" id="location-map" @initMap="initMap($event)"></VueNaverMap>
      <VueNaverPano v-model="isOpenPano" ref="naverPano" id="pano-map" left="40px" bottom="30px" @onOpenPano="updateOpenPano($event)"></VueNaverPano>
      <b-button pill variant="primary" v-show="!isOpenPano && userLocations !== null" type="button" class="position-absolute btn-pano" @click="openPano()"><i class="bx bx-street-view font-size-24"></i></b-button>
    </div>
  </b-modal>
</template>

<style scoped>
.select-date {
  position: absolute;
  z-index:9999;
  right: 10px;
}
.map {
  left:0;
  right: 0;
  top:0;
  bottom:0;
  transition: all 0.5s ease;
  overflow:hidden;
}
.btn-pano {
  width: 50px;
  height: 50px;
  right: 20px;
  bottom: 40px;
}

/* Safe Zones */
.zone-box {
  position: absolute;
  left: 10px;
  top: 10px;
  background: white;
  padding: 10px;
  border: 1px solid #b6a3d1;
  border-radius: 10px;
  z-index: 99999;
}
.zone-box .zone-item {
  padding: 5px 20px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #ddd;
  background: transparent;
  cursor: pointer;
  margin-bottom: 5px;
}
.zone-box .zone-item:last-child {
  margin-bottom: 0;
}
.zone-box .zone-item.active-zone {
  background: #512771;
  border: 1px solid #512771;
  color: white;
}
</style>