<script>
import store from "@/state/store";
import Swal from "sweetalert2";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core/dist/index.esm";
import {getFileUrl, getImageRateUrl} from "@/helpers/api-config";
import VueEasyLightbox from "vue-easy-lightbox";
import moment from "moment";
import Loader from "@/components/widgets/loader";

import PasswordModal from "@/components/modals/change-password";
import DeviceModal from "@/components/modals/device-modal";
import LocationModal from "@/components/modals/user-location";

export default {
  name : 'basic',

  setup() {
    return { v$: useVuelidate() };
  },

  components: {
    VueEasyLightbox,
    PasswordModal,
    DeviceModal,
    LocationModal,
    Loader,
  },

  props : {
    userType : {
      type: String,
      default: ''
    },
    userUid : {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      uid: this.$props.userUid,
      type: this.$props.userType,
      genderOptions: [
        { text: '남자', value: 'Men' },
        { text: '여자', value: 'Women' }
      ],
      data: {},
      additions: {},
      formData: {
        uid: "",
        type: "",
        name: "",
        phone: "",
        email: "",
        user_password: "",
        gender: "Men",
        birth_day: "",
      },
      formSubmitted: false,
      imgIndex: 0,
      imgVisible: false,
      showModal: {
        password: false,
        device: false,
        location: false
      },
      modalData: {},
      isPageLoading: false,
    }
  },

  mounted() {
    this.formData.uid = this.uid;
    this.loadData();
  },

  validations: function() {
    let _validation = {
      formData: {
        name: {
          required: helpers.withMessage("회원이름을 입력해 주세요.", required)
        },
        phone: {
          required: helpers.withMessage("휴대폰번호를 입력해 주세요.", required)
        },
        email: {
          required: helpers.withMessage("이메일을 입력해 주세요.", required)
        },
      }
    };
    if (!this.uid) {
      _validation.formData.user_password = {
        required: helpers.withMessage("비밀번호를 입력해 주세요.", required)
      }
    }

    return _validation;
  },

  methods: {
    loadData() {
      let type = this.type
      let uid = this.uid

      this.isPageLoading = true
      store.dispatch("apiUsers/create", { type, uid }).then((result) => {
        this.data = result.data.data
        console.log(this.data.ip);
        if (this.data.birth_day) {
          this.data.birth_day = new Date(this.data.birth_day)
        }
        this.data.images = []
        this.data.thumbnails = []
        if (this.data.photos && this.data.photos.length > 0) {
          this.data.photos.forEach(row => {
            this.data.images.push(getFileUrl(row.uid))
            this.data.thumbnails.push(getImageRateUrl("h100", row.uid))
          })
        }
        if (this.data) {
          this.formData.uid = this.data.uid
          this.formData.type = this.data.type
          this.formData.name = this.data.name
          this.formData.phone = this.data.phone
          this.formData.email = this.data.email
          this.formData.user_password = ""
          this.formData.gender = this.data.gender
          this.formData.birth_day = this.data.birth_day
        }
        this.additions = result.data.additions
//          console.log(this.additions)
        this.isPageLoading = false
      }, () => {
        this.isPageLoading = false
      })
    },

    showImage(index) {
      this.imgIndex = index;
      this.imgVisible = true;
    },
    hideImage() {
      this.imgVisible = false;
    },
    changeImage(e) {
      const file = e.target.files;
      let message = '';

      if (file[0].type.indexOf('image') < 0) {
        message = '이미지 파일만 업로드 가능합니다.';
      }

      if (!message) {
        this.formData.file = file[0]
      } else {
        Swal.fire(message, "", "error");
      }
    },

    // 저장여부 체크
    confirmSubmit() {
      store.dispatch('notification/clear')

      // stop here if form is invalid
      this.v$.$touch();

      if (!this.v$.formData.$invalid) {
        Swal.fire({
          text: this.uid ? "회원정보를 수정하시겠습니까?" : "회원을 등록하시겠습니까?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "예",
          cancelButtonText: "아니요"
        }).then(result => {
          if (result.value) {
            this.submitForm()
          }
        });
      }
    },

    // 저장
    submitForm() {
      this.formSubmitted = true

      let params = {
        uid: this.formData.uid ? this.formData.uid : '',
        type: (this.type == 'admins') ? 'Admin' : 'User',
        name: this.formData.name,
        phone: this.formData.phone,
        email: this.formData.email,
        password: !this.uid ? this.formData.user_password : "",
        gender: this.formData.gender
      }
      if (this.formData.birth_day) {
        params.birth_day = moment(this.formData.birth_day).format('YYYY-MM-DD')
      }
      if (this.formData.file) {
        params.file = this.formData.file
      }

      this.formSubmitted = false
      store.dispatch("apiUsers/store", params).then((result) => {
        Swal.fire({
          text: result.message,
          icon: result.success ? "success" : "error"
        }).then(result => {
          if (result.value) {
            if (this.uid) {
              window.location.reload()
            } else {
              this.$router.go(-1)
            }
          }
        });
      })
    },

    // 탈퇴처리
    signOut() {
      Swal.fire({
        text: "현재 회원을 탈퇴처리 하시겠습니까?",
        icon: "warning",
        confirmButtonText: "탈퇴",
        cancelButtonText: "취소",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.requestSignOut()
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('cancel')
        }
      });
    },

    // 탈퇴 서버요청
    requestSignOut() {
      let type = this.type
      let uid = this.uid
      let params = {}
      if (uid) {
        store.dispatch("apiUsers/signout", { type, uid, params }).then((result) => {
          Swal.fire({
            text: result.message,
            icon: result.success ? "success" : "error",
            confirmButtonText: "확인",
          }).then(result => {
            if (result.value) {
              window.location.reload()
            }
          });
        });
      }
    },

    // 복구처리
    restore() {
      Swal.fire({
        text: "현재 회원을 복구 하시겠습니까?",
        icon: "warning",
        confirmButtonText: "복구",
        cancelButtonText: "취소",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.requestRestore()
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('cancel')
        }
      });
    },

    // 복구 서버요청
    requestRestore() {
      let type = this.type
      let uid = this.uid
      let params = {}
      if (uid) {
        store.dispatch("apiUsers/restore", { type, uid, params }).then((result) => {
          Swal.fire({
            text: result.message,
            icon: result.success ? "success" : "error",
            confirmButtonText: "확인",
          }).then(result => {
            if (result.value) {
              window.location.reload()
            }
          });
        });
      }
    },

    // 인증요청
    authRequest() {
      Swal.fire({
        text: "["+ this.data.name +"] 회원님께 인증코드를 전송하시겠습니까?",
        icon: "warning",
        confirmButtonText: "전송",
        cancelButtonText: "취소",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.authSend()
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('cancel')
        }
      });
    },

    // 인증요청 서버요청
    authSend() {
      let params = {
        uid: this.uid
      }
      if (params.uid) {
        store.dispatch("apiUsers/authSend", params).then((result) => {
          Swal.fire({
            text: result.message,
            icon: result.success ? "success" : "error",
            confirmButtonText: "확인",
          }).then(result => {
            if (result.value) {
              this.loadData()
            }
          });
        });
      }
    },

    // 인증완료 처리
    authComplete(uid) {
      Swal.fire({
        text: "인증완료 처리하시겠습니까?",
        icon: "warning",
        confirmButtonText: "완료",
        cancelButtonText: "취소",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.requestAuthComplete(uid)
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('cancel')
        }
      });
    },

    // 인증완료 서버요청
    requestAuthComplete(uid) {
      let params = {
        uid: uid
      }
      if (params.uid) {
        store.dispatch("apiUsers/authComplete", params).then((result) => {
          Swal.fire({
            text: result.message,
            icon: result.success ? "success" : "error",
            confirmButtonText: "확인",
          }).then(result => {
            if (result.value) {
              this.loadData()
            }
          });
        });
      }
    },

    changePassword() {
      this.modalData = {
        uid: this.uid,
        name: this.data.name
      }
      this.showModal.password = true
    },

    deviceInfo() {
      this.additions.name = this.data.name
      this.modalData = this.additions
      this.showModal.device = true
    },

    openLocation() {
      this.modalData = this.data
      this.showModal.location = true
    },

    goList() {
      let url = '/users'
      if (this.type == 'admins') {
        url += '/admin'
      } else if (this.type == 'search') {
        url += '/'+ this.type
      }
      this.$router.push(url)
    },

    openVirtual() {
      let width = 1024;
      let height = 768;
      let posLeft = screen.width ? (screen.width - width) / 2 : 10;
      let posTop = screen.height ? (screen.height - height) / 2 - 80 : 10;

      let attr = 'width='+ width +',height='+ height +',top='+ posTop +',left='+ posLeft +',resizable=no,status=no';
      let win = window.open(process.env.VUE_APP_API_URL +'/admin/virtual/login?uid=' + this.data.uid +'&url=/admin/virtual/login?uid=' + this.data.uid, 'virtualLogin', attr);
      win.focus();
    },
  },

  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    noneImage() {
      return require('@/assets/images/no-image.png')
    },
    labelEmail() {
      return this.$route.params.type == 'admins' ? '아이디' : '이메일'
    },
    statusText() {
      let text = '';
      switch(this.data.status) {
        case 'Waiting':
          text = '<span class="text-warning">'+ this.data.created_at +' (대기)</span>';
          break;
        case 'SignOut':
          text = '<span class="text-danger">'+ this.data.created_at +' (탈퇴)</span>';
          break;
        default:
          text = '<span class="text-dark">'+ this.data.created_at +' (승인)</span>';
      }
      return text;
    },
    authAction() {
      return !this.additions.auth_status ? '인증 요청' : '재요청'
    },
  }
}
</script>

<template>
  <password-modal v-if="showModal.password" :modal-data="modalData" @close="showModal.password = false" />
  <device-modal v-if="showModal.device" :modal-data="modalData" @close="showModal.device = false" />
  <location-modal v-if="showModal.location" :modal-data="modalData" @close="showModal.location = false" />

  <div class="position-relative" style="padding-top: -15px;" v-show="!isPageLoading">
    <div v-if="notification.message" :class="'alert ' + notification.type">{{ notification.message }}</div>
    <b-form @submit.prevent="confirmSubmit" enctype="multipart/form-data">
      <b-row align-h="between">
        <b-col class="text-start mt-2 text-nowrap">
          <b-button variant="primary" class="btn-rounded me-2" :disabled="formSubmitted" @click="goList()">목록</b-button>
          <span v-if="uid && data.type != 'Admin'" class="d-inline-block">
            <b-button variant="info" class="btn-rounded me-2" @click="deviceInfo()">기기</b-button>
            <b-button variant="warning" class="btn-rounded me-2" @click="openLocation()">위치</b-button>
          </span>
          <b-button variant="success" class="btn-rounded me-2" type="submit" :disabled="data.status == 'SignOut'">
            <span v-if="uid">수정</span>
            <span v-else>등록</span>
          </b-button>
        </b-col>
        <b-col class="text-start text-md-end mt-2 text-nowrap">
          <span class="d-inline-block" v-if="uid">
            <b-button v-if="data.type != 'Admin' && (data.ip == '::1' || data.ip == '127.0.0.1' || data.ip == '59.3.32.129')" variant="primary" class="btn-rounded me-2" @click="openVirtual()">가상 로그인</b-button>
            <b-button v-if="data.type != 'Admin'" variant="dark" class="btn-rounded me-2" @click="authRequest()">{{ authAction }}</b-button>
            <b-button v-if="data.status != 'SignOut' && !data.social_id" variant="success" class="btn-rounded me-2" @click="changePassword()">비밀번호</b-button>
            <b-button v-if="data.status != 'SignOut'" variant="danger" class="btn-rounded me-2" @click="signOut()">회원 탈퇴</b-button>
            <b-button v-if="data.status == 'SignOut'" variant="dark" class="btn-rounded me-2" @click="restore()">회원복구</b-button>
          </span>
        </b-col>
      </b-row>
      <div v-if="additions.auth_status" class="mt-3">
        <div v-if="additions.auth_status == 90" class="form-control bg-success bg-opacity-10 text-dark text-start text-sm-end">
          <strong>인증완료 ({{ additions.sms_code }})</strong> : <span class="spacing-10">{{ additions.auth_date }}</span>
        </div>
        <div v-else class="form-control bg-danger bg-opacity-10 text-dark text-nowrap text-start text-sm-end">
          <strong class="text-danger">인증요청중 ({{ additions.sms_code }})</strong> : <span class="spacing-10">{{ additions.auth_date }}</span>&nbsp;
          <b-button variant="success" size="sm" class="btn-rounded" @click="authComplete(additions.auth_uid)">인증완료</b-button>
        </div>
      </div>
      <b-form-group
          class="mt-3"
          label="회원이름"
          label-for="name"
          :invalid-feedback="v$.formData.name.$error ? v$.formData.name.$errors[0].$message : null"
          :state="!v$.formData.name.$error"
      >
        <b-form-input
            id="name"
            name="name"
            type="text"
            v-model="formData.name"
            :state="!v$.formData.name.$error"
            :disabled="formSubmitted || data.status === 'SignOut'"
        ></b-form-input>
      </b-form-group>
      <b-form-group
          class="mt-3"
          label="휴대폰번호"
          label-for="phone"
          :invalid-feedback="v$.formData.phone.$error ? v$.formData.phone.$errors[0].$message : null"
          :state="!v$.formData.phone.$error"
      >
        <b-form-input
            id="phone"
            name="phone"
            type="text"
            v-model="formData.phone"
            :state="!v$.formData.phone.$error"
            :disabled="formSubmitted || data.status === 'SignOut'"
        ></b-form-input>
      </b-form-group>
      <b-form-group
          class="mt-3"
          :label="labelEmail"
          label-for="email"
          :invalid-feedback="v$.formData.email.$error ? v$.formData.email.$errors[0].$message : null"
          :state="!v$.formData.email.$error"
      >
        <b-form-input
            id="email"
            name="email"
            type="text"
            v-model="formData.email"
            :state="!v$.formData.email.$error"
            :disabled="formSubmitted || data.status === 'SignOut'"
        ></b-form-input>
      </b-form-group>
      <b-form-group
          v-if="!uid"
          class="mt-3"
          label="비밀번호"
          label-for="user_password"
          :invalid-feedback="v$.formData.user_password.$error ? v$.formData.user_password.$errors[0].$message : null"
          :state="!v$.formData.user_password.$error"
      >
        <b-form-input
            id="user_password"
            name="user_password"
            type="password"
            v-model="formData.user_password"
            :state="!v$.formData.user_password.$error"
            :disabled="formSubmitted || data.status === 'SignOut'"
        ></b-form-input>
      </b-form-group>
      <div v-if="type != 'admins'">
        <b-form-group
            v-if="data.social_type"
            class="mt-3"
            label="소셜 구분"
            label-for="social_type"
        >
          <b-form-input
              id="social_type"
              name="social_type"
              type="text"
              disabled="disabled"
              v-model="data.social_type"
          ></b-form-input>
        </b-form-group>
        <b-form-group
            v-if="data.social_id"
            class="mt-3"
            label="소셜 ID"
            label-for="social_id"
        >
          <b-form-input
              id="social_id"
              name="social_id"
              type="text"
              disabled="disabled"
              v-model="data.social_id"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="mt-3" label="성별">
          <b-form-radio-group
              class="form-control"
              :class="{'bg-light': data.status == 'SignOut'}"
              id="gender"
              v-model="formData.gender"
              :options="genderOptions"
          >
          </b-form-radio-group>
        </b-form-group>
        <div class="mt-3">
          <label for="birth_day">생년월일</label>
          <div class="col-sm-2 search-date">
            <date-picker
                input-class="form-control"
                v-model:value="formData.birth_day"
                value-type="date"
                :disabled="data.status == 'SignOut'"
            ></date-picker>
          </div>
        </div>
        <div class="mt-3">
          <label for="thumb" class="form-label">회원사진</label>
          <div class="input-group">
            <input class="form-control" type="file" accept="image/*" id="file" @change="changeImage" :disabled="data.status == 'SignOut'" />
            <span v-for="(src, index) in data.thumbnails"
                  :key="index"
                  class="d-block pointer"
                  :style="`padding:0.47rem 1.5rem;background-image:url(${src}), url(${noneImage});background-position:center center;background-size:cover;margin-right:3px;`"
                  @click="() => showImage(index)"
            ></span>
          </div>
          <div v-if="data.images">
            <vue-easy-lightbox
                loop="true"
                moveDisabled
                maxZoom="3"
                :visible="imgVisible"
                :imgs="data.images"
                :index="imgIndex"
                @hide="hideImage"
            ></vue-easy-lightbox>
          </div>
        </div>
        <div v-if="uid" class="mt-3">
          <label class="form-label">초대코드</label>
          <div class="form-control bg-light">{{ data.my_referral_code ? data.my_referral_code : '-' }}</div>
        </div>
      </div>
      <div v-if="uid">
        <div class="mt-3">
          <label class="form-label">가입일시</label>
          <div class="form-control" v-html="statusText"></div>
        </div>
        <div v-if="data.deleted_at" class="mt-3">
          <label class="form-label">탈퇴일시</label>
          <div class="form-control bg-light"><span class="text-danger">{{ data.deleted_at }}</span></div>
        </div>
      </div>
      <div v-if="uid && type != 'admins'">
        <div class="mt-3">
          <label class="form-label">접속상태</label>
          <div class="form-control bg-light">
            <div v-if="additions.device_os">
              <img v-if="additions.device_os == 'Android'" :src="require(`@/assets/images/social/icon_android.png`)" height="18" class="me-1" v-b-tooltip.hover title="Android" />
              <img v-if="additions.device_os == 'iOS'" :src="require(`@/assets/images/social/icon_apple.png`)" height="18" class="me-1" v-b-tooltip.hover title="Apple" />
              <img v-if="additions.device_os == 'Android'" :src="require(`@/assets/images/device/ic_state_power${ !additions.is_power ? '_off' : '' }.png`)" height="18" class="me-1" v-b-tooltip.hover :title="additions.is_power ? 'ON' : 'OFF'" />
              <img :src="require(`@/assets/images/device/ic_state_gps${ !additions.is_gps ? '_off' : '' }.png`)" height="18" class="me-1" v-b-tooltip.hover :title="additions.is_gps ? 'ON' : 'OFF'" />
              <img v-if="additions.device_os == 'Android'" :src="require(`@/assets/images/device/ic_state_mobile${ !additions.is_mobile ? '_off' : '' }.png`)" height="18" class="me-1" v-b-tooltip.hover :title="additions.is_mobile ? 'ON' : 'OFF'" />
              <img v-if="additions.device_os == 'Android'" :src="require(`@/assets/images/device/ic_state_wifi${ !additions.is_wifi ? '_off' : '' }.png`)" height="18" class="me-1" v-b-tooltip.hover :title="additions.is_wifi ? 'ON' : 'OFF'" />
            </div>
            <span v-else>-</span>
          </div>
        </div>
        <div class="mt-3">
          <label class="form-label">최근 위치</label>
          <div class="form-control bg-light">
            <span class="font-size-12" :class="{ 'text-danger': !additions.last_address }">{{ additions.last_address ? additions.last_address : '수집 X' }}</span>
          </div>
        </div>
      </div>
      <div v-if="additions.last_date" class="mt-3">
        <label class="form-label">최근 접속일시</label>
        <div class="form-control bg-light">
          <span :class="{ 'text-dark': additions.last_login, 'text-danger': !additions.last_login }">{{ additions.last_date }}</span>
          <span v-if="additions.last_ip"> ({{ additions.last_ip }})</span>
          <span v-if="type != 'admins'"> ({{ additions.last_login ? 'IN' : 'OUT' }})</span>
        </div>
      </div>
    </b-form>
  </div>
  <div class="position-relative py-5" v-show="isPageLoading">&nbsp;</div>
  <Loader :loading="isPageLoading" class="position-absolute base-list-loader mt-4"></Loader>
</template>
