<script>
import VueNaverMap from "@/components/widgets/vue-naver-map";

export default {
  components: {
    VueNaverMap,
  },
  name : 'FamilyZoneModal',
  props : ["modalData"],
  data() {
    return {
      data: this.modalData,
      showModal: false,
      naverMap: null,
      mapOptions: {
        lat: 37,
        lng: 127,
        zoom: 10,
        zoomControl: true,
        zoomControlOptions: {position: 'TOP_RIGHT'},
        mapTypeControl: true,
      },
      isLoaded: false,
      zones: [],
    }
  },
  mounted() {
    this.showModal = true;
    setTimeout(()=> {
      this.isLoaded = true;
    }, 1000)
  },
  methods: {
    openModal() {
      window.history.pushState({}, '', '#modal');
      window.onpopstate = history.onpushstate = () => {
        this.showModal = false
      }
    },

    closeModal() {
      this.$emit('close');
      window.history.back()
    },

    initMap(naverMap) {
      this.naverMap = naverMap;
      this.requestZones();
    },

    clearMap() {
      this.naverMap.clearZone();
    },

    requestZones() {
      this.clearMap();

      let rows = this.data.list;
      if (rows.length > 0) {
        for (let i in rows) {
          let item = rows[i];
          this.zones.push(item);
          this.showZone(i);
        }
      }
    },

    showZone(i) {
      this.clearZoneList();
//      let item = this.zones[i];
//      let uid = item.uid;
      let element = document.querySelector('#family-zone'+ i);
//      if (this.naverMap.zones[uid]) {
//        element.classList.remove('active-zone');
//        this.naverMap.removeZone(uid);
//      } else {
        element.classList.add('active-zone');
        this.naverMap.addZone(this.zones[i]);
//      }
    },

    clearZoneList() {
      document.querySelectorAll(".zone-item").forEach(item => {
        item.classList.remove('active-zone');
      });
    },
  },
}
</script>

<template>
  <b-modal
      v-model="showModal"
      id="user-location-modal"
      @show="openModal"
      @hidden="closeModal"
      centered
      fullscreen
      :title="data.childName +`님 안심존`"
      title-class="font-15 fw-bold"
      hide-footer
  >
    <div class="position-absolute map">
      <div v-if="data.list.length" class="zone-box">
        <div class="text-center font-size-13 fw-bold mb-2">{{ data.parentName }}</div>
        <div class="zone-list">
          <div v-for="(zone, index) in data.list" :key="zone.uid" :id="`family-zone`+ index" class="zone-item" @click="showZone(index)">{{ zone.name }}</div>
        </div>
      </div>
      <VueNaverMap v-if="isLoaded" ref="naverMap" id="location-map" @initMap="initMap($event)"></VueNaverMap>
    </div>
  </b-modal>
</template>

<style scoped>
.map {
  left:0;
  right: 0;
  top:0;
  bottom:0;
  transition: all 0.5s ease;
  overflow:hidden;
}

/* Safe Zones */
.zone-box {
  position: absolute;
  left: 10px;
  top: 10px;
  background: white;
  padding: 10px;
  border: 1px solid #b6a3d1;
  border-radius: 10px;
  z-index: 99999;
}
.zone-box .zone-item {
  padding: 5px 20px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #ddd;
  background: transparent;
  cursor: pointer;
  margin-bottom: 5px;
}
.zone-box .zone-item:last-child {
  margin-bottom: 0;
}
.zone-box .zone-item.active-zone {
  background: #512771;
  border: 1px solid #512771;
  color: white;
}
</style>