<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import basic from "./details/basic"
import relations from "./details/relations"
import points from "./details/points"
import recommends from "./details/recommends"
import counsel from "./details/counsel"

/**
 * Editors component
 */

export default {
  components: {
    Layout,
    PageHeader,
    basic,
    relations,
    points,
    recommends,
    counsel,
  },
  data() {
    return {
      menuId: 200100,
      menuCode: "users-search",
      baseData: {},
      currentTab: 'basic',
      tabIndex: 0
    };
  },
  created() {
    let type = this.$route.params.type
    if (type != 'search') {
      this.menuId = 200200
      this.menuCode = type.substr(0, type.length-1)
    }
    this.$changeMenu(this.menuId, this.menuCode)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
//      let {type, uid} = this.$route.params
//      console.log(type, uid)

      let tab = this.$route.query.tab
      let tabIndex = 0
      if (tab == "counsel") {
        tabIndex = 4
      } else if (tab == "recommends") {
        tabIndex = 3
      } else if (tab == "points") {
        tabIndex = 2
      } else if (tab == "relations") {
        tabIndex = 1
      } else {
        tab = 'basic'
      }
      this.changeTab(tab, tabIndex)
    },

    changeTab(selectedTab, selectedIndex) {
      // 동적 컴포넌트 변경
      this.currentTab = selectedTab
      this.tabIndex = selectedIndex
      console.log(this.currentTab)
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader/>
    <div class="row">
      <div class="col-12">
        <b-card no-body class="px-3 py-3">
          <b-tabs class="user-tabs" v-if="$route.params.type != 'admins' && $route.params.uid" active-nav-item-class="text-danger fw-bold" v-model="tabIndex">
            <b-tab @click="changeTab('basic',0)">
              <template v-slot:title>
                <span>기본정보</span>
              </template>
            </b-tab>
            <b-tab @click="changeTab('relations',1)">
              <template v-slot:title>
                <span>관계</span>
              </template>
            </b-tab>
            <b-tab @click="changeTab('points',2)">
              <template v-slot:title>
                <span>포인트</span>
              </template>
            </b-tab>
            <b-tab @click="changeTab('recommends',3)">
              <template v-slot:title>
                <span>추천인</span>
              </template>
            </b-tab>
            <b-tab @click="changeTab('counsel',4)">
              <template v-slot:title>
                <span>상담내역</span>
              </template>
            </b-tab>
          </b-tabs>
          <div class="px-1 py-3">
            <keep-alive>
              <component :is="currentTab" :user-type="$route.params.type" :user-uid="$route.params.uid"></component>
            </keep-alive>
          </div>
        </b-card>
      </div>
    </div>
  </Layout>
</template>

