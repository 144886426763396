<script>
import store from "@/state/store";
import Tablesaw from 'tablesaw'
import {reloadTablesaw} from "@/helpers/datatable";
import PointModal from "@/components/modals/point-modal";

export default {
  name: 'recommends',

  components: {
    PointModal,
  },

  props : {
    userType : {
      type: String,
      default: ''
    },
    userUid : {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      uid: this.$props.userUid,
      type: this.$props.userType,
      user: {},
      points: {},
      showModal: {
        point: false,
      },
      modalData: {},
    }
  },

  mounted() {
    this.loadPoints()
  },

  methods: {
    loadPoints() {
      Tablesaw._init();

      let uid = this.uid
      store.dispatch("apiUsers/points", uid).then((result) => {
        this.user = result.data.user
        this.user.point_number = this.user.point.toLocaleString()
        this.points = result.data.points
        this.$nextTick(function () {
          reloadTablesaw(this.$refs.points)
        })
      })
    },

    openPoint(data) {
      data.user = this.user.name
      this.modalData = data
      this.showModal.point = true
    },
  },
};
</script>

<template>
  <point-modal v-if="showModal.point" :modal-data="modalData" @close="showModal.point = false" />

  <div class="row">
    <div class="col-12">
      <div class="font-size-14 pb-3"><span class="fw-semibold">{{ user.name }}</span>님의 포인트 : <span class="text-primary fw-semibold">{{ user.point_number }}</span> P</div>
      <table ref="points" class="tablesaw table align-middle table-nowrap table-hover" data-tablesaw-mode="columntoggle">
        <thead class="table-light">
        <tr>
          <th scope="col" class="wd-100" data-tablesaw-priority="persist">구분</th>
          <th scope="col" class="text-start" data-tablesaw-priority="persist">지급내용</th>
          <th scope="col" class="wd-80" data-tablesaw-priority="persist">지급포인트</th>
          <th scope="col" class="wd-120" data-tablesaw-priority="3">지급일시</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="list in points" :key="list.uid">
          <td><a class="dotted-link" href="javascript:void(0);" @click="openPoint(list)"><span :class="{'text-primary': list.point > 0, 'text-danger': list.point <= 0}">{{ list.title }}</span></a></td>
          <td class="text-start ellipsis ellipsis-wide"><span>{{ list.memo }}</span></td>
          <td><span :class="{'text-primary': list.point > 0, 'text-danger': list.point <= 0}">{{ list.point }}</span></td>
          <td>{{ list.created_at.substring(2,16).replaceAll('-','.') }}</td>
        </tr>
        </tbody>
      </table>
      <div v-if="!points.length">
        <p class="py-3 text-secondary text-center">포인트 내역이 없습니다.</p>
      </div>
    </div>
  </div>
</template>
