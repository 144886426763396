<script>
/* eslint-disable no-undef */
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    left: {
      type: String,
      default: "0px",
    },
    bottom: {
      type: String,
      default: "0px",
    }
  },
  data() {
    return {
      baseMapCenter : null,
      mapOptions : {
        center: this.baseMapCenter,
        zoom: 10
      },
      maps : null,
      panorama: null,
      marker: null,
      isOpenPano: false,
    }
  },
  methods: {
    open(latitude, longitude) {
      this.isOpenPano = true
      this.initMap(latitude, longitude)
      this.$nextTick(function () {
        setTimeout(()=> {
          this.updatePosition(latitude, longitude)
        }, 500)
      })
      this.$emit('onOpenPano', this.isOpenPano)
    },
    close() {
      this.isOpenPano = false
      this.$emit('onOpenPano', this.isOpenPano)
    },
    updatePosition(latitude, longitude) {
      let position = new naver.maps.LatLng(latitude, longitude)
      this.marker.setPosition(position);
      this.panorama.setPosition(position);
    },
    initMap(latitude, longitude) {
      let _this = this
      if (!latitude || !longitude) {
        latitude = 37.58043041966419
        longitude = 126.97708894395505
      }

      let position = new naver.maps.LatLng(latitude, longitude)

      if(this.maps == null) {
        this.baseMapCenter = position
        this.maps = new naver.maps.Map('vue-naver-panorama-minimap-' + this.id, this.mapOptions)
      }

      if(this.marker == null) {
        this.marker = new naver.maps.Marker({
          position: position,
          map: this.maps
        });
      }

      if(this.panorama == null) {
        this.panorama = new naver.maps.Panorama('vue-naver-panorama-' + this.id, {
          position: position,
          pov: {
            pan: -133,
            tilt: 0,
            fov: 100
          }
        });

        // 파노라마 위치가 갱신되었을 때 발생하는 이벤트를 받아 지도의 중심 위치를 갱신합니다.
        naver.maps.Event.addListener(this.panorama, 'pano_changed', function() {
          let latlng = _this.panorama.getPosition();
          if (!latlng.equals(_this.maps.getCenter())) {
            _this.maps.setCenter(latlng);
            _this.marker.setPosition(latlng);
          }
        });

        // 거리뷰 레이어를 생성합니다.
        let streetLayer = new naver.maps.StreetLayer();
        streetLayer.setMap(this.maps);

        // 지도를 클릭했을 때 발생하는 이벤트를 받아 파노라마 위치를 갱신합니다. 이때 거리뷰 레이어가 있을 때만 갱신하도록 합니다.
        naver.maps.Event.addListener(this.maps, 'click', function(e) {
          if (streetLayer.getMap()) {
            let latlng = e.coord;
            // 파노라마의 setPosition()은 해당 위치에서 가장 가까운 파노라마(검색 반경 300미터)를 자동으로 설정합니다.
            _this.panorama.setPosition(latlng);
          }
        });

      }
      else {
        this.panorama.setPosition(position);
      }

      this.maps.setZoom(16);
    },
  },

};
</script>

<template>
  <div class="position-absolute full-box panorama-box" :class="{'open-panorama' : isOpenPano}">
    <div class="position-absolute full-size" :id="'vue-naver-panorama-' + id"></div>
    <div class="position-absolute mini-map" :style="{'left': left, 'bottom': bottom }">
      <div :id="'vue-naver-panorama-minimap-' + id" class="position-absolute full-size"></div>
    </div>

    <button type="button" class="btn-close position-absolute" style="right:2px;top:2px;font-size: 20pt;background-color: white;" @click="close"></button>
  </div>
</template>

<style scoped>
.panorama-box {
  right: -110% !important;
}
.open-panorama {
  right: 0 !important;
}
.full-box {
  bottom:10px;
  top:10px;
  width: 100%;
  right:0;
}
.full-size {
  left:0;
  bottom:0;
  top:0;
  right:0;
}
.mini-map {
  z-index: 1;
  left:0;
  bottom:0;
  width:200px;
  height:100px;
}
</style>