<script>
import store from "@/state/store";
import {helpers, required} from "@vuelidate/validators";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core/dist/index.esm";
import Loader from "@/components/widgets/loader";

export default {
  name: 'counsel',

  setup() {
    return { v$: useVuelidate() };
  },

  components: {
    Loader,
  },

  props : {
    userType : {
      type: String,
      default: ''
    },
    userUid : {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      uid: this.$props.userUid,
      type: this.$props.userType,
      user: {},
      data: {},
      showEdit: false,
      formData: {
        id: 0,
        user_uid: 0,
        content: "",
        status: 0
      },
      formSubmitted: false,
      isPageLoading: false,
    }
  },

  mounted() {
    this.loadCounsel();
    this.autoResizeTextarea();
  },

  validations: function() {
    return {
      formData: {
        content: {
          required: helpers.withMessage("상담내용을 입력해 주세요.", required)
        },
      }
    }
  },

  methods: {
    loadCounsel() {
      let uid = this.uid
      store.dispatch("apiUserCounsel/list", uid).then((result) => {
        this.user = result.data.user;
        this.data = result.data.data;
        this.formData.user_uid = this.user.uid;
        this.isPageLoading = false
      }, () => {
        this.isPageLoading = false
      });
    },

    initCounsel() {
      store.dispatch('notification/clear')
      this.v$.$reset();

      this.formData.id = 0;
      this.formData.content = '';
      this.showEdit = false;
      this.$nextTick(function () {
        this.autoResizeTextarea();
      });
    },

    modifyCounsel(row) {
      this.formData.id = row.id;
      this.formData.content = row.content;
      this.formData.status = row.status;
      this.showEdit = true;
      this.$nextTick(function () {
        this.autoResizeTextarea();
      });
    },

    // 저장
    submitForm() {
      store.dispatch('notification/clear')

      this.v$.$touch();

      this.formSubmitted = true

      if (this.v$.formData.$invalid) {
        this.formSubmitted = false
      } else {
        let params = {
          id: this.formData.id,
          user_uid: this.formData.user_uid,
          content: this.formData.content,
          status: this.formData.status
        }

        store.dispatch("apiUserCounsel/store", params).then((result) => {
          this.formSubmitted = false
          Swal.fire({
            text: result.message,
            icon: result.success ? "success" : "error"
          }).then(result => {
            if (result.value) {
              this.loadCounsel();
              this.initCounsel();
            }
          });
        }, () => {
          this.formSubmitted = false
        });
      }
    },

    // 삭제
    deleteCounsel(id) {
      store.dispatch('notification/clear')

      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "한 번 삭제된 데이터는 복구할 수 없습니다.",
        icon: "warning",
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.requestDeleteCounsel(id)
        }
      });
    },

    // 삭제 서버요청
    requestDeleteCounsel(id) {
      let params = {
        id : id
      }
      if (params.id) {
        this.formSubmitted = true
        store.dispatch("apiUserCounsel/erase", params).then((result) => {
          Swal.fire(result.message, "", result.success ? "success" : "error");
          this.formSubmitted = false
          this.loadCounsel();
          this.initCounsel();
        }, () => {
          this.formSubmitted = false
        });
      }
    },

    autoResizeTextarea() {
      let el = document.querySelector('#content');
      let offset = el.offsetHeight - el.clientHeight;
      if (parseInt(el.scrollHeight) > 75) {
        el.style.height = 'auto';
        el.style.height = el.scrollHeight + offset + "px";
      }
      el.addEventListener('input', function (e) {
        if (parseInt(e.target.scrollHeight) > 75) {
          e.target.style.height = 'auto';
          e.target.style.height = e.target.scrollHeight + offset + 'px';
        }
      });
    },
  },

  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
};
</script>

<template>
  <div class="pb-2 font-size-13">
    <span class="fw-semibold">{{ user.name }} ({{ $formatPhone(user.phone) }})</span>
  </div>
  <div class="counsel-form">
    <div v-if="notification.message" :class="'alert ' + notification.type">{{ notification.message }}</div>
    <b-form @submit.prevent="submitForm">
      <input type="hidden" name="user_uid" :value="formData.user_uid" />
      <input type="hidden" name="id" :value="formData.id" />
      <input type="hidden" name="status" :value="formData.status" />
      <b-form-group
          class="d-inline-block align-top"
          style="width:calc(100% - 80px);"
          :invalid-feedback="v$.formData.content.$error ? v$.formData.content.$errors[0].$message : null"
          :state="!v$.formData.content.$error"
      >
        <b-form-textarea
          id="content"
          name="content"
          v-model="formData.content"
          class="form-control d-inline-block align-top autosize"
          style="height:75px;"
          placeholder="상담내용을 입력해 주세요."
          :state="!v$.formData.content.$error"
          :disabled="formSubmitted"
        ></b-form-textarea>
      </b-form-group>
      <b-button type="submit" v-show="!showEdit" variant="danger" class="align-top px-4 ms-1" style="height:75px;">등록</b-button>
      <div v-show="showEdit" class="align-top ms-1" :class="{'d-inline-block': showEdit }">
        <b-button type="submit" variant="danger" class="px-4 py-1">수정</b-button><br>
        <b-button type="button" variant="secondary" class="px-4 py-1 mt-1" @click="initCounsel()">취소</b-button>
      </div>
   </b-form>
  </div>
  <div class="position-relative" v-show="!isPageLoading">
    <dl v-for="row in data" :key="row.id">
      <dt class="pb-1 fw-normal font-size-13">
        <span class="text-secondary">{{ row.create_user.name }}</span>
        <span class="font-size-12"><i class="bx bx-time align-middle ms-1"></i> {{ row.created_at }}</span>
        <i class="bx bxs-edit text-danger pointer font-size-17 align-middle ms-2" @click="modifyCounsel(row)"></i>
        <i class="bx bx-trash text-secondary pointer font-size-17 align-middle ms-1" @click="deleteCounsel(row.id)"></i>
      </dt>
      <dd class="form-control" style="background-color:#fff6f6;" v-html="$nl2br(row.content)"></dd>
    </dl>
    <div class="position-relative py-5 text-secondary text-center" v-show="data.length === 0">상담내역이 없습니다.</div>
  </div>
  <Loader :loading="isPageLoading" class="position-absolute base-list-loader mt-5"></Loader>
</template>
