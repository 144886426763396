<script>
export default {
  name : 'ZoneModal',
  props : ["modalData"],
  data() {
    return {
      data : this.modalData,
      showModal : false,
    }
  },
  mounted() {
//    console.log(this.modalData)
    this.showModal = true;
  },
  methods: {
    openModal() {
      window.history.pushState({}, '', '#modal');
      window.onpopstate = history.onpushstate = () => {
        this.showModal = false
      }
    },

    closeModal() {
      this.$emit('close');
      window.history.back()
    },
  }
}
</script>

<template>
  <b-modal
      v-model="showModal"
      id="modal-box"
      @show="openModal"
      @hidden="closeModal"
      centered
      title="안심존 정보"
      title-class="font-size-15 fw-bold"
      hide-footer
  >
    <div class="row mb-2 align-items-center">
      <div class="mb-2">
        <div class="mb-1 fw-semibold">피보호자</div>
        <div class="form-control bg-light"><a class="pointer dotted-link" :href="`/users/${$route.params.type}/${data.child_uid}`">{{ data.child_name }}</a>&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">휴대폰</div>
        <div class="form-control bg-light"><a class="pointer dotted-link" :href="`/users/${$route.params.type}/${data.child_uid}`">{{ data.child_phone }}</a>&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">유형</div>
        <div class="form-control bg-light">{{ data.code_name }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">안심존명</div>
        <div class="form-control bg-light">{{ data.name }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">주소</div>
        <div class="form-control bg-light">{{ data.address }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">반경</div>
        <div class="form-control bg-light">{{ data.radius }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">상태</div>
        <div class="form-control bg-light">
          <span v-if="data.action_code == 'out'" class="text-danger">이탈</span>
          <span v-else class="text-primary">진입</span>
        </div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">등록일</div>
        <div class="form-control bg-light">{{ data.created_at }}&nbsp;</div>
      </div>
    </div>
  </b-modal>
</template>
