<script>
// import {getImageUrl} from '@/helpers/api-config';
import {formatPhone, toHHMM} from "@/helpers/helpers";
import {getImageUrl} from "@/helpers/api-config";
import moment from "moment/moment";

/* eslint-disable no-undef */
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      naverMapScript : document.createElement("script"),
      clusteringScript : document.createElement("script"),
      baseMapCenter : null,
      mapOptions : {
        center: this.baseMapCenter,
        zoom: 10
      },
      maps : null,
      bounds : null,
      markers : [],
      infowindows : [],
      polylines: null,

      clusterMarker1 : null,
      clusterMarker2 : null,
      clusterMarker3 : null,
      clusterMarker4 : null,
      clusterMarker5 : null,
      markerClustering: null,

      // 안심존
      zones: [],
      zoneMarkers: [],
      zoneInfoWindows: [],
    }
  },
  methods: {
    getMap() {
      return this.maps
    },

    removeScript() {
      document.head.removeChild(this.naverMapScript)
      document.head.removeChild(this.clusteringScript)
    },

    addScript() {
      let API_KEY_ID = process.env.VUE_APP_NAVER_API_KEY_ID
      this.naverMapScript.setAttribute("src","https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId="+API_KEY_ID+"&submodules=panorama")
      this.clusteringScript.setAttribute("src","/js/navermap/MarkerClustering.js")

      this.naverMapScript.addEventListener("load", () =>{
        this.baseMapCenter = new naver.maps.LatLng(37.58043041966419, 126.97708894395505)
        this.bounds  = new naver.maps.LatLngBounds()
        this.maps =new naver.maps.Map('vue-naver-map-' + this.id, this.mapOptions)
        this.maps.setCenter(this.baseMapCenter)
        this.maps.setZoom(10)

        document.head.appendChild(this.clusteringScript)
        this.$emit('initMap', this)
      });

      document.head.appendChild(this.naverMapScript)

    },

    onImageError(e) {
      e.target.src = require('@/assets/images/users/avatar-0.png')
    },

    // id 값으로 마커를 삭제 한다.
    removeMarkerAll() {
      for (let i in this.markers) {
        let row = this.markers[i]
        row.setMap(null)
      }

      for (let i in this.infowindows) {
        let row = this.infowindows[i]
        row.setMap(null)
      }

      this.bounds = new naver.maps.LatLngBounds();
      this.markers = [];
      this.infowindows = [];
    },

    resetBounds() {
      this.bounds = new naver.maps.LatLngBounds();
    },

    // id 값으로 마커를 삭제 한다.
    removeMarkerFromId(uid) {
      if(!this.markers[uid]) return;

      var marker = this.markers[uid];
      if(marker) marker.setMap(null);

      var infowindow = this.infowindows[uid];
      if(infowindow) infowindow.setMap(null);

      delete this.markers[uid];
      delete this.infowindows[uid];
    },

    // 지도에 마커를 추가합니다.
    addColorMarker(color, uid, lat, lng, name) {
      var isMarker = false;
      var marker = this.markers[uid];
      if(marker) {
        isMarker = true;
        // 마커 위치 갱신
        this.updateMarker(marker, lat, lng);
      }
      if(isMarker) return;

      var contentHtml = '<div class="position-relative" style="width: 50px; height: 55px;">' +
          '<span class="rounded-circle position-absolute bg-'+color+' border-'+color+'" style="display:inline-block;width:50px;height:50px;"></span>' +
          '<i class="bx bxs-map position-absolute text-'+color+'" style="bottom:-4px;color:#886ab5;font-size: 50px;"></i>' +
          '<div class="position-absolute text-center" style="color:white;font-weight:bold;left: 50%;top:50%;margin-top:-3px;line-height: normal;transform: translateY(-50%) translateX(-50%);font-size: 9pt;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;width:50px;">' + name + '</div>' +
          '</div>';

      marker = new naver.maps.Marker({
        position: new naver.maps.LatLng( parseFloat(lat), parseFloat(lng) ),
        map: this.maps,
        id: uid,
        icon: {
          content: contentHtml,
          size: new naver.maps.Size(50, 55),
          anchor: new naver.maps.Point(50/2, 55)
        }
      });

      this.bounds.extend(marker.position);
      this.markers[uid] = marker;
    },

    createInfoWindow(content) {
      return new naver.maps.InfoWindow({
        content: content,
        borderWidth: 2,
        borderColor: '#886ab5',
        backgroundColor: '#eee',

        anchorSize: new naver.maps.Size(10, 10),
        anchorSkew: true,
        anchorColor: "#eee",
        pixelOffset: new naver.maps.Point(0, 0),
      });
    },

    // 지도에 마커를 추가합니다.
    addUserMarker(uid, lat, lng, photo_uid, name, phone, data, isNoMap, callback) {
      var isMarker = false;
      var marker = this.markers[uid];
      if(marker) {
        isMarker = true;
        // 마커 위치 갱신
        this.updateMarker(marker, lat, lng);
      }
      if(isMarker) return marker;

      var contentHtml = '<div class="position-relative" style="width: 50px; height: 55px;">' +
          '<img src="'+getImageUrl(50, 50, photo_uid)+'" onerror="this.onImageError" width="50" height="50" class="rounded-circle position-absolute" style="z-index: 1;border: 2px solid #886ab5;">' +
          '<i class="bx bxs-map position-absolute" style="z-index: 0;bottom:-4px;color:#886ab5;font-size: 50px;"></i>' +
          '<div class="position-absolute text-center" style="z-index: 1;color:white;font-weight:bold;left: 50%;top:50%;margin-top:-3px;line-height: normal;transform: translateY(-50%) translateX(-50%);font-size: 9pt;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;width:50px;">' + name + '</div>' +
          '</div>';

      marker = new naver.maps.Marker({
        position: new naver.maps.LatLng( parseFloat(lat), parseFloat(lng) ),
        // map: this.maps,
        id: uid,
        data: data,
        icon: {
          content: contentHtml,
          size: new naver.maps.Size(50, 55),
          anchor: new naver.maps.Point(50/2, 55)
        }
      });
      if (typeof isNoMap == 'undefined' || isNoMap != false) {
        marker.setMap(this.maps)
      }

      var infowindow = new naver.maps.InfoWindow({
        content: this.generatorContentHtml(uid, photo_uid, name, phone),
        borderWidth: 2,
        borderColor: '#886ab5',
        backgroundColor: '#eee',

        anchorSize: new naver.maps.Size(10, 10),
        anchorSkew: true,
        anchorColor: "#eee",
        pixelOffset: new naver.maps.Point(0, 0),
      });
      // infowindow.open(this.maps, marker);

      let _this = this;
      naver.maps.Event.addListener(marker, 'click', function() {
        // showUserData(marker);
        if (infowindow.getMap()) {
          infowindow.close();
        } else {
          infowindow.open(_this.maps, marker);
        }
        if(callback) callback(data)
      });

      this.bounds.extend(marker.position);
      this.markers[uid] = marker;
      this.infowindows[uid] = infowindow;

      return marker;
    },

    createLatLng(latitude, longitude) {
      return new naver.maps.LatLng( latitude, longitude );
    },

    drawLine(paths) {
      this.clearLine();
      this.polylines = new naver.maps.Polyline({
        map: this.maps,
        path: paths,
        clickable: true,
        strokeColor: '#5347AA',
        strokeStyle: 'solid',
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
        strokeOpacity: 0.5,
        strokeWeight: 10,
      });
    },

    clearLine() {
      if(this.polylines) {
        this.polylines.setMap(null)
        this.polylines = null
      }
    },

    addFitBounds(paths) {
      for (let i in paths) {
        this.bounds.extend(paths[i])
      }
    },

    initClusterIcon() {
      this.clusterMarker1 = {
        content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(/js/navermap/images/cluster-marker-1.png);background-size:contain;"></div>',
            size: N.Size(40, 40),
            anchor: N.Point(20, 20)
      }
      this.clusterMarker2 = {
        content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(/js/navermap/images/cluster-marker-2.png);background-size:contain;"></div>',
            size: N.Size(40, 40),
            anchor: N.Point(20, 20)
      }
      this.clusterMarker3 = {
        content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(/js/navermap/images/cluster-marker-3.png);background-size:contain;"></div>',
            size: N.Size(40, 40),
            anchor: N.Point(20, 20)
      }
      this.clusterMarker4 = {
        content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(/js/navermap/images/cluster-marker-4.png);background-size:contain;"></div>',
            size: N.Size(40, 40),
            anchor: N.Point(20, 20)
      }
      this.clusterMarker5 = {
        content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(/js/navermap/images/cluster-marker-5.png);background-size:contain;"></div>',
            size: N.Size(40, 40),
            anchor: N.Point(20, 20)
      }
    },

    showMarkerClustering() {
      //console.log('showMarkerClustering')
      if(this.markerClustering) this.markerClustering.setMap(this.maps)
    },

    hideMarkerClustering() {
      //console.log('hideMarkerClustering')
      if(this.markerClustering) this.markerClustering.setMap(null)
    },

    loadMarkerClustering() {
      this.initClusterIcon()

      let markers = []
      for (let i in this.markers) {
        markers.push(this.markers[i])
      }
      this.markerClustering =new MarkerClustering({
        minClusterSize: 2,
        maxZoom: 16,
        map: this.maps,
        markers: markers,
        disableClickZoom: false,
        gridSize: 120,
        icons: [this.clusterMarker1, this.clusterMarker2, this.clusterMarker3, this.clusterMarker4, this.clusterMarker5],
        indexGenerator: [10, 100, 200, 500, 1000],
        stylingFunction: function(clusterMarker, count) {
          // console.log(clusterMarker.getElement().childNodes[0])
          clusterMarker.getElement().childNodes[0].innerHTML = count;
        }
      });
    },

    fitBounds(paths) {
      this.addFitBounds(paths)
      let options = { top: 100, right: 100, bottom: 100, left: 100, maxZoom: 16 }
      this.maps.fitBounds(this.bounds, options)
    },

    mapSetCenter(lat, lng) {
      let point = new naver.maps.LatLng(lat, lng);

      this.maps.setCenter(point)
      this.maps.setZoom(18)
    },

    // 마커 위치를 갱신한다.
    updateMarker(marker, lat, lng) {
      var newLatLng = new naver.maps.LatLng(lat, lng);
      marker.setPosition(newLatLng);
      // updateBounds();
    },

    // 마커 위에 뜨는 레이어 HTML 을 만듭니다.
    generatorContentHtml(uid, photo_uid, name, phone) {
      var contentHtml = '<div class="p-2">\n' +
          '    <div>\n' +
          '        <div><i class="bx bxs-user"></i> #{name}</div>\n' +
          '        <div><i class="bx bxs-phone"></i> #{phone}</div>\n' +
          '    </div>';
      contentHtml = contentHtml.replace("#{name}", name);
      contentHtml = contentHtml.replace("#{phone}", formatPhone(phone));
      contentHtml = contentHtml.replace("#{id}", uid);
      contentHtml = contentHtml.replace("#{id}", uid);
      return contentHtml;
    },

    // 마커 정보를 띄운다.
    openInfoWindow(latitude, longitude, content) {
      let position = this.createLatLng(latitude, longitude);
      let infoWindow = this.createInfoWindow(content);
      this.maps.setCenter(position);
      infoWindow.open(this.maps, position);
    },

    // 숫자 마커 정보 HTML 생성
    getNumberInfoHtml(data) {
      let start = data.timestamp.substring(11, 19);
      let end = data.updated_at.substring(11, 19);
      if (data.staytime) {
        end = null;
        if(data.staytime >= 60 * 5) {
          let staytime = moment(data.timestamp).add(data.staytime, 'seconds');
          end = staytime.format('HH:mm');
        }
      }
      // var end = data.updated_at.substring(11, 16);

      let contentHtml = '';
      if (end !== null && start !== end) {
        contentHtml += '<div class="p-2 position-relative">' +
            '    <div>' +
            '        <div>#{start} ~ #{end}#{staytime}</div>' +
            '        <div>#{address}</div>' +
            '        <div>#{provider} #{activity_type} ' +
            '        <div>배터리: #{battery}% | 정확도: #{accuracy}m</div>' +
            '    </div>';
      } else {
        contentHtml += '<div class="p-2">' +
            '    <div>' +
            '        <div>#{start}#{staytime}</div>' +
            '        <div>#{address}</div>' +
            '        <div>#{provider} #{activity_type} ' +
            '        <div>배터리: #{battery}% | 정확도: #{accuracy}m</div>' +
            '    </div>';
      }
      contentHtml = contentHtml.replace("#{start}", start);
      contentHtml = contentHtml.replace("#{end}", end);
      if (data.staytime >= 60) {
        contentHtml = contentHtml.replace("#{staytime}", " ("+ toHHMM(data.staytime) +")");
      } else {
        contentHtml = contentHtml.replace("#{staytime}", "");
      }
      contentHtml = contentHtml.replace("#{address}", (data.address ? data.address : '-'));
      contentHtml = contentHtml.replace("#{battery}", data.battery);
      contentHtml = contentHtml.replace("#{accuracy}", Math.round(data.accuracy));
      contentHtml = contentHtml.replace("#{provider}", data.provider);
      contentHtml = contentHtml.replace("#{activity_type}", data.activity_type);

      return contentHtml;
    },

    // 숫자 마커 추가
    addNumberMarker(number, data) {
      let uid = 'n'+ number;
      let marker = this.markers[uid];
      if (marker) {
        this.updateMarker(marker, data.latitude, data.longitude);
        return;
      }

      let numberHtml = '<div class="position-relative"><div class="position-absolute rounded-circle number-marker">'+ number +'</div></div>';
      marker = new naver.maps.Marker({
        position: new naver.maps.LatLng(parseFloat(data.latitude), parseFloat(data.longitude)),
        id: uid,
        icon: {
          content: numberHtml,
          size: new naver.maps.Size(20, 20),
          anchor: new naver.maps.Point(20/2, 20)
        }
      });
      marker.setMap(this.maps)

      let infoHtml = this.getNumberInfoHtml(data);
      let infoWindow = this.createInfoWindow(infoHtml);

      let _this = this;
      naver.maps.Event.addListener(marker, 'click', function() {
        if (infoWindow.getMap()) {
          infoWindow.close();
        } else {
          infoWindow.open(_this.maps, marker);
        }
      });

      this.bounds.extend(marker.position);
      this.markers[uid] = marker;
      this.infowindows[uid] = infoWindow;

      return marker;
    },

    // 안심존 마커 정보 HTML 생성
    getZoneInfoHtml(data) {
      let radius = '-';
      if (data.radius > 1000) {
        radius = (data.radius / 1000) + 'km';
      } else {
        radius = data.radius + 'm';
      }

      let contentHtml = '<div class="p-2">' +
          '    <div>' +
          '        <div>#{name} (#{radius})</div>' +
          '        <div>#{address}</div>' +
          '    </div>';
      contentHtml = contentHtml.replace("#{name}", data.name);
      contentHtml = contentHtml.replace("#{radius}", radius);
      contentHtml = contentHtml.replace("#{address}", data.address);

      return contentHtml;
    },

    // 안심존 로드
    loadZones(rows) {
      this.clearZone();

      for (let i in rows) {
        let data = rows[i];
        if (data && data.uid) {
          this.addZone(data);
        }
      }
    },

    // 존 추가
    addZone(data) {
      let uid = data.uid;
      let zone = this.zoneMarkers[uid];
      if (zone) {
        this.maps.setCenter(this.createLatLng(data.latitude, data.longitude));
        this.zoneInfoWindows[data.uid].open(this.maps);

        return zone;
      }

      let position = this.createLatLng(parseFloat(data.latitude), parseFloat(data.longitude));
      this.maps.setCenter(position)
      this.maps.setZoom(17)

      let marker = new naver.maps.Marker({
        position: position,
        map: this.maps,
        id: uid,
      });

      let infoWindow = new naver.maps.InfoWindow({
        content: this.getZoneInfoHtml(data),
        borderWidth: 2,
        borderColor: 'green',
        backgroundColor: '#eee',

        anchorSize: new naver.maps.Size(10, 10),
        anchorSkew: true,
        anchorColor: "#eee",
        pixelOffset: new naver.maps.Point(0, 0),
      });
      infoWindow.open(this.maps, marker);

      let _this = this;
      naver.maps.Event.addListener(marker, 'click', function() {
        if (infoWindow.getMap()) {
          infoWindow.close();
        } else {
          infoWindow.open(_this.maps, marker);
        }
      });

      let circle = new naver.maps.Circle({
        center: position,
        map: this.maps,
        id: uid,
        radius: data.radius,
        fillColor: '#512771',
        fillOpacity: 0.1,
        strokeColor: '#512771',
        strokeOpacity: 0.3,
      });

      this.zones[uid] = circle;
      this.zoneMarkers[uid] = marker;
      this.zoneInfoWindows[uid] = infoWindow;

      return marker;
    },

    // 지도에서 안심존 제거
    removeZone(uid) {
      if (this.zones[uid]) {
        this.zones[uid].setMap(null)
      }

      if (this.zoneMarkers[uid]) {
        this.zoneMarkers[uid].setMap(null)
      }

      if (this.zoneInfoWindows[uid]) {
        this.zoneInfoWindows[uid].setMap(null)
      }

      this.zones[uid] = null;
      this.zoneMarkers[uid] = null;
      this.zoneInfoWindows[uid] = null;
    },

    // 안심존 초기화
    clearZone() {
      for (let i in this.zones) {
        let marker = this.zones[i]
        marker.setMap(null)
      }

      for (let i in this.zoneMarkers) {
        let marker = this.zoneMarkers[i]
        marker.setMap(null)
      }

      for (let i in this.zoneInfoWindows) {
        let marker = this.zoneInfoWindows[i]
        marker.setMap(null)
      }

      this.zones = [];
      this.zoneMarkers = [];
      this.zoneInfoWindows = [];
    },
  },

  mounted() {
    this.addScript();
  },

  unmounted() {
    this.removeScript();
  }
};
</script>

<template>
  <div :id="'vue-naver-map-' + id" class="position-absolute full-size"></div>
</template>

<style scoped>
.full-size {
  left:0;
  bottom:0;
  top:0;
  right:0;
}
</style>